import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Misc} from "../../components/core";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
// import Loader from "../../components/core/Loader";
// import Layout from "../../components/containers/Layout";
// import Layout from "./Layout1";
// import PatientsPanel from "./Panel1";
import TopNav from "../../components/nav/TopNav";
import "../../pages/messages/Messages.scss";
import "./BottomMargin1.scss";



// MARGIN MODE TEST
const marginMode = 2; // 0 = off, 1 = current, 2 = proposed

// DEBUG
const debug = 0;


export default function Page(){
    const dispatch = useDispatch();
    const [panelMode, setPanelMode] = React.useState(false);
    // const [minPanel, setMinPanel] = React.useState(false);

    // const [showActivity, setActivity] = React.useState(false);
    
    React.useEffect(() => {
        // setTimeout(() => setPanel(true), 1000)

    }, []);

    // const onCTA = () => dispatch({call: "SHOW_MODAL", component: <ChatModal />});
    // const onClickToggle = (value) => dispatch({call: "TOGGLE_MESSAGES_PANEL", value: value, });
    // const onClickToggle = () => setPanel(!showPanel);

    const onClickPanelClose = () => {
        // setPanel(false);
        setPanelMode(false);
    };

    const onClickPanelMin = () => {
        setPanelMode('min');
        // setMinPanel(true);
    };

    const onClickPanelMax = () => {
        setPanelMode('max');
        // setMinPanel(false);
    }

    const onClickRow = () => {
        // setPanelMode(panelMode === 'max' ? false : 'max'); // --- disable the panel opening
    };


    // render
    const css = utils.classNames("lds-layout2", panelMode && `is-panelmode-${panelMode}`, marginMode && `is-marginmode-${marginMode}`);
    return (
        <React.Fragment>

            {/* <TopNav /> */}

            <div className={css}>

                <Sprite.Button  className="lds-accessibility-button" style={{position: 'fixed', bottom: 10, right: 20, width: 44, height: 44, zIndex: 1000, }} />
            

                <div className="lds-layout2-top">
                    <TopNav />
                </div>

                <div className="lds-layout2-content">
                    <div className="lds-layout2-left">
                        <div id="lds-tasks-sidenav"></div>
                    </div>
                    <div className="lds-layout2-main">
                        
                        <div className="lds-list2">
                            <div className="lds-list2-top">


                                <Sprite.Split id="lds-tasks2">
                                    <Sprite.Left />
                                    <Sprite.Right />
                                </Sprite.Split>

                            </div>
                            <div className="lds-list2-content-outer">

                                <div className="lds-list2-content">

                                    <Sprite.Split id="lds-tasks3" onClick={() => onClickRow()}>
                                        <Sprite.Left />
                                        <Sprite.Right />
                                    </Sprite.Split>

                                </div>

                            </div>
                            <div className="lds-list2-bottom"></div>
                        </div>
{/*   
                        <div className="lds-layout2-main-bottom">

                        </div>
                         */}
{/* 
                        <Sprite.Split id="lds-tasks" onClick={() => onClickRow()}>
                            <Sprite.Left />
                            <Sprite.Right />
                        </Sprite.Split> */}


                    </div>
                    <div className="lds-layout2-right is-overlay222">

                        <div className="lds-panel2">
                            <div className="lds-panel2-top is-abs-all">

                                {panelMode !== 'min' && <Sprite.Button  debug={debug} style={{top: 20, left: 316, width: 30, height: 30, zIndex: 2, }} onClick={() => onClickPanelClose()} />}
                                <Sprite.Button  debug={debug} style={{top: 20, left: 285, width: 30, height: 30, zIndex: 2, }} onClick={() => onClickPanelMin()} />
                                {panelMode === 'min' && <Sprite.Button className="lds-panel2-overlay"   debug={debug} style={{top: 0, left: 0, width: 50, height: 62, }} onClick={() => onClickPanelMax()} />}
                                {panelMode === 'min' && <Sprite.Button className="lds-panel2-x"  debug={debug} style={{top: 16, left: 7, width: 40, height: 40, zIndex: 2, }} onClick={() => onClickPanelClose()} />}
                                {panelMode === 'min' && <Sprite.Button className="lds-panel2-chevrons"  debug={debug} style={{top: 54, left: 7, width: 40, height: 40, zIndex: 2, }} onClick={() => onClickPanelMax()} />}
                                
                            </div>
                            <div className="lds-panel2-content-outer">
                                <div className="lds-panel2-content">

                                    <Sprite.Button className="lds-panel2-overlay"  debug={0} style={{top: 0, left: 0, width: 50, height: 1000, }} onClick={() => onClickPanelMax()} />

                                </div>
                            </div>
                        </div>
{/* 
                        <div className="lds-panel2">
                            <div className="lds-panel2-top"></div>

                        </div>
                        <div id="lds-tasks-panel-top"></div>
                        <div id="lds-tasks-panel"></div>
                         */}

                    </div>
                </div>
{/* 
                <div className="lds-layout-left" style={{height: 200, }}>

                </div>
                <div className="lds-layout-main" style={{height: 200, }}>
                
                </div>
                <div className="lds-layout-right" style={{height: 200, }}>

                </div> */}


            </div>

{/*             
            <Layout.WithPanel showPanel={showPanel}>
                <Layout.Sidenav spriteId="lds-messages-sidenav">

                    <Sprite.Button debug={debug} to="/messages/send" style={{top: 20, left: 100, width: 80, }} />
                    <Sprite.Button debug={debug} to="/messages/auto" style={{top: 20, left: 180, width: 80, }} />

                    <Layout.Selection  debug={debug} label="All" style={{top: 143, }} />
                    <Layout.Hotspot debug={debug} style={{top: 140, height: 340, }} />

                </Layout.Sidenav>
                <Layout.Content noPadding style={{paddingTop: 0, paddingLeft: 24, }}>

                    <Sprite.Split id="lds-tasks">
                        <Sprite.Left />
                        <Sprite.Right />
                    </Sprite.Split>

                </Layout.Content>
                <Layout.Panel noPadding onClickClose={onClickToggle}>

                    <PatientsPanel />

                </Layout.Panel>
            </Layout.WithPanel> */}


        </React.Fragment>

    )

    // END PAGE
}