import React, { useState } from 'react'
import {STATE} from "./settings";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from './context';
import TopNav from "../components/nav/TopNav";
import {App} from "./classes";
import Dialog from '@mui/material/Dialog';
import Mobile from "../components/containers/Mobile";
import reducers from './reducers';
import Loader from "../components/core/Loader";
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';

// css
import "@fontsource/lato"; // defaults to weight 400
import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";
import "../styles/styles.scss";


// screens (app)
import LoginScreen from "../screens/auth/LoginScreen";
import HomeScreen from "../screens/home/HomeScreen";
import MedsScreen from "../screens/meds/MedsScreen";
import MedsOrdersScreen from "../screens/meds/MedsOrdersScreen";
import MedsRemindersScreen from "../screens/meds/MedsRemindersScreen";
import MedsRefillScreen from "../screens/meds/MedsRefillScreen";
import MedsInsCardsScreen from "../screens/meds/MedsInsCardsScreen"; // --- NEW
import MedsReadyScreen from "../screens/meds/MedsReadyScreen";
import MessagesScreen from "../screens/messages/MessagesScreen";
import ChatScreen from "../screens/messages/ChatScreen";
import ApptsScreen from "../screens/appts/ApptsScreen";
import AccountScreen from "../screens/account/AccountScreen";
import AccountPersonalScreen from "../screens/account/AccountPersonalScreen";
import PushScreen from "../screens/general/PushScreen";


// poges (desktop)
import LoginPage from '../pages/auth/LoginPage';
import DashboardPage from '../pages/dashboard/DashboardPage';
import DashboardPage3 from '../pages/dashboard/DashboardPage3';
import DashboardPage4 from '../pages/dashboard/DashboardPage4';
// import DemoPage from '../pages/demo/DemoPage'; // demo
import TasksListPage from '../pages/tasks/TasksListPage';
import TasksPage from '../pages/tasks/TasksPage';
import TasksGridPage from '../pages/tasks/TasksGridPage';
import TasksAutoPage from '../pages/tasks/TasksAutoPage';
import SchedulePage from '../pages/schedule/SchedulePage';
import ScheduleAddPage from '../pages/schedule/ScheduleAddPage';
import MessagesInboxPage from '../pages/messages/MessagesPage';
import MessagesSendPage from '../pages/messages/MessagesSendPage';
import MessagesDetailsPage from "../pages/messages/MessagesDetailsPage";
import MessagesAutoPage from "../pages/messages/MessagesAutoPage";
import PatientsPage from '../pages/patients/PatientsPage';
import PatientsActivityPage from '../pages/patients/PatientsActivityPage';
import FormsPage from '../pages/forms/FormsPage';
import FormsFormsPage from '../pages/forms/FormsFormsPage';
import FormsDetailsPage from '../pages/forms/FormsDetailsPage';
import FormsEditorPage from '../pages/forms/FormsEditorPage';
import MarketingPage from '../pages/marketing/MarketingPage';
import WebappPage from '../pages/webapp/WebappPage';
// misc
import VideosPage from '../pages/misc/VideosPage';
import VideosDetailsPage from '../pages/misc/VideosDetailsPage';
import VideosListPage from '../pages/misc/VideosListPage';
import WellnessPage from '../pages/misc/ClassesPage';
import RefillsPage from '../pages/misc/RefillsPage';
import AutoPage from '../pages/auto/AutoPage';
// settings
import SettingsPage from '../pages/settings/SettingsPage';


// --- demos --- //
import DemosPage from '../demos/ui/DemosPage';
import Onboard1 from '../demos/onboard1/Onboard1';
import Onboard2 from '../demos/onboard2/Onboard2';
import DetailsPanel1 from '../demos/detailspanel1/DetailsPanel1';
import BottomMargin1 from '../demos/bottommargin1/BottomMargin1';
// import Onboard2Page from '../demos/onboard2/Onboard2Page';



// modules (KEEP HERE)
import tasksModule from "../modules/tasks";
const modules = [tasksModule];



// --- start --- //

var app = new App();

function makeReducer(module, method){
  return (state, action) => {return module[method](state, action);};
}


// func
function start(){

    // iterate modules
    modules.forEach(module => {
        let reducers2 = module.reducers;
        module.app = app;

        // load state data
        STATE[`${module.name}_rows`] = module.data;

        // load reducers
        if(reducers2){
          reducers2.forEach(key => {
            reducers[`${module.name}.${key}`] = makeReducer(module, key);
          });
        }

        // load handlers - copy handlers to app
        Object.keys(module.handlers).forEach(key => {
            const funcs = module.handlers[key];
            funcs.forEach(func => app.on(key, func));
        });
        module.handlers = {}; // reset

        // load module
        if(module.table) module.table.loadState(STATE);
        module.load(STATE);
    })

    // trigger modules loaded
    app.trigger('modulesLoaded');
}

start();


// --- components --- //


function Wrapper({children}){
    const dispatch = useDispatch();
    const globals = useGlobals();
    const {showModal, showMobile, showLoader, modalComponent, isAuth,  } = globals;
    const location = useLocation();
    const nav = useNavigate();
    const [css, setCss] = useState('lds-page');
    const [initiated, setInitiated] = useState(false);
    const [isMobile, setMobile] = useState(false);
    
    // page change
    React.useEffect(() => {
        const urlPath = location?.pathname;
        const parts = urlPath?.split('/') || ['', ''];
        const page = parts[1] || "home";
        const mobile = (page == "app");


        // close modal on page change
        if(showModal) dispatch({call: 'HIDE_MODAL'});

        // check auth !!!
        if(!isAuth){
          // if(localStorage.getItem("demoAuth") === 'true'){
          //     dispatch({call: "SET_AUTH", value: true, });
          // } else 
          if(page !== 'login'){
            let loginUrl = isMobile ? "/app/login" : "/login";
            if(page !== 'logout') dispatch({call: "SET_REDIRECT", value: urlPath, });
            nav(loginUrl);
          }
        }

        // /// console.log("========== LOCATION: " + page);
        // setTimeout(() => {
        //   // console.log("========== LOCATION 2222: " + document.getElementById("lds-maincontent222"));
        //   // window.scrollTo(0, 0);
        //   // document.getElementById("lds-maincontent").scrollTo(0, 0);
        //   document.getElementById("lds-maincontent").scrollTop = 0; // --- NOT WORKING
        // }, 1000);

        // document.getElementById("lds-maincontent").scrollTop = 0; // --- NOT WORKING
        // document.getElementById("lds-maincontent").scroll({
        //   top: 0,
        //   behavior: 'smooth'
        // });

        // set css
        setMobile(mobile);
        setCss("lds-page lds-page-" + page + (mobile ? ' is-mobile' : ' is-desktop'));


        document.body.id = "lds-body" + urlPath.split("/").join("-");
        document.body.className = "lds-body-" + page + (mobile ? " lds-is-mobile" : " lds-is-desktop");
        // document.html.className = "asdf-" + page;
        // var body = document.body;
        // body.classList.add("MyClass");


        // trigger init page
        if(!initiated){
            setInitiated(true);
            app.trigger('initPage', location);
        }

        // trigger page change
        app.trigger('pageChange', location);
    }, [location]);



    // close modal handler
    const handleClose = () => {
        console.log("------- handleClose");
        dispatch({call: "HIDE_MODAL"});
    }

    // close modal handler
    const handleCloseMobile = () => {
        // dispatch({call: "HIDE_MODAL"});
        dispatch({call: "HIDE_MOBILE"});

    }

    // clock mobile icon
    const onClickMobile = () => {
      dispatch({call: "SHOW_MOBILE"});
      setTimeout(() => {
        nav("/app/home");
        dispatch({call: "HIDE_MOBILE"});
      }, 300);
    }

    // console.log("showModal: " + showModal);

    
    // mobile render
    if(isMobile) return (
      <Mobile.GlobalWrapper>
        <Dialog container={() => document.getElementById('lds-mobile-content')} onClose={handleClose} open={showModal}>{modalComponent}</Dialog>
        {children}
      </Mobile.GlobalWrapper>
    )

    // desktop render
    return (
        <React.Fragment>
          {showLoader && <Loader open duration={20000} />}
          <div className={css}>
              <TopNav />
              <Dialog onClose={handleClose} open={showModal}>{modalComponent}</Dialog> 
              <Mobile.Fade onClick={handleCloseMobile} open={showMobile} />
              {isAuth && <Mobile.Button onClick={onClickMobile} />}
              <div className='lds-maincontent'>{children}</div>
          </div>
        </React.Fragment>

    );
}


function TempPage(){return <div>File not found</div>}

export default function Main() {
  const {isSales, } = useGlobals();

  let DefaultPage = isSales ? DashboardPage : TempPage;

  return (
    <Router>
      <Wrapper>
        <Routes>
          
          {/* --- app --- */}
          <Route exact path="/app/" element={<HomeScreen/>}/>
          <Route exact path="/app/login/*" element={<LoginScreen/>}/>
          <Route exact path="/app/home/*" element={<HomeScreen />}/>
          <Route exact path="/app/home/refillready" element={<HomeScreen notification="refillready" />}/>
          <Route exact path="/app/meds/*" element={<MedsScreen/>}/>
          <Route exact path="/app/meds/orders/*" element={<MedsOrdersScreen/>}/>
          <Route exact path="/app/meds/reminders/*" element={<MedsRemindersScreen/>}/>
          <Route exact path="/app/meds/refill" element={<MedsRefillScreen/>}/>
          <Route exact path="/app/meds/refill/ins2" element={<MedsRefillScreen.Ins2/>}/>
          <Route exact path="/app/meds/refill/info" element={<MedsRefillScreen.Info/>}/>
          <Route exact path="/app/meds/refill/method" element={<MedsRefillScreen.Method/>}/>
          <Route exact path="/app/meds/refill/review" element={<MedsRefillScreen.Review/>}/>
          <Route exact path="/app/meds/refill/confirm" element={<MedsRefillScreen.Confirm/>}/>
          <Route exact path="/app/meds/refill/*" element={<DefaultPage/>}/>
          <Route exact path="/app/meds/inscards" element={<MedsInsCardsScreen />}/>{/** NEW */}
          <Route exact path="/app/meds/ready" element={<MedsReadyScreen/>}/>
          <Route exact path="/app/messages/*" element={<MessagesScreen/>}/>
          <Route exact path="/app/messages/chat/*" element={<ChatScreen/>}/>
          <Route exact path="/app/appts/*" element={<ApptsScreen/>}/>
          <Route exact path="/app/appts/details/*" element={<ApptsScreen.Details/>}/>
          <Route exact path="/app/account/*" element={<AccountScreen/>}/>
          <Route exact path="/app/account/personal/*" element={<AccountPersonalScreen/>}/>
          <Route exact path="/app/account/people/*" element={<AccountScreen.People/>}/>
          <Route exact path="/app/push/*" element={<PushScreen/>}/>
          <Route exact path="/app/push/refillready/*" element={<PushScreen/>}/>
          {/* <Route exact path="/app/*" element={<DemoPage/>}/> */}

          {/* --- desktop --- */}
          <Route exact path="/" element={<DashboardPage4 />}/>
          <Route exact path="/dashboard3" element={<DashboardPage3 />}/>
          <Route exact path="/login/*" element={<LoginPage/>}/>
          <Route exact path="/logout/*" element={<LoginPage logout />}/>
          <Route exact path="/messages/*" element={<MessagesInboxPage />}/>
          <Route exact path="/messages/send/*" element={<MessagesSendPage />}/>
          <Route exact path="/messages/details/*" element={<MessagesDetailsPage />}/>
          <Route exact path="/messages/auto/*" element={<MessagesAutoPage />}/>
          <Route exact path="/tasks/*" element={<TasksPage/>}/>
          <Route exact path="/tasks/grid/*" element={<TasksGridPage/>}/>
          <Route exact path="/tasks/auto/*" element={<TasksAutoPage/>}/>
          <Route exact path="/schedule/*" element={<SchedulePage/>}/>
          <Route exact path="/schedule/add/*" element={<ScheduleAddPage/>}/>
          <Route exact path="/patients/*" element={<PatientsPage/>}/>
          <Route exact path="/patients/activity/*" element={<PatientsActivityPage/>}/>
          <Route exact path="/forms/*" element={<FormsPage/>}/>
          <Route exact path="/forms/forms/*" element={<FormsFormsPage/>}/>
          <Route exact path="/forms/details/*" element={<FormsDetailsPage/>}/>
          <Route exact path="/forms/editor/*" element={<FormsEditorPage/>}/>
          <Route exact path="/marketing/*" element={<MarketingPage/>}/>
          <Route exact path="/videos/*" element={<VideosPage/>}/>
          <Route exact path="/videos/details/*" element={<VideosDetailsPage/>}/>
          <Route exact path="/videos/list/*" element={<VideosListPage/>}/>
          <Route exact path="/classes/*" element={<WellnessPage/>}/>
          <Route exact path="/refills/*" element={<RefillsPage/>}/>
          <Route exact path="/settings/*" element={<SettingsPage.General/>}/>
          <Route exact path="/settings/corporate/*" element={<SettingsPage.Corporate/>}/>
          <Route exact path="/auto/*" element={<AutoPage />}/>
          <Route exact path="/webapp/*" element={<WebappPage />}/>

          {/* --- demos --- */}
          <Route exact path="/demos/*" element={<DemosPage />}/>
          <Route exact path="/demos/onboard1/*" element={<Onboard1 />}/>
          <Route exact path="/demos/onboard2/*" element={<Onboard2 />}/>
          {/* <Route exact path="/demos/onboard1/welcome/*" element={<Onboard1.Welcome />}/>
          <Route exact path="/demos/onboard1/end/*" element={<Onboard1.End />}/> */}
          <Route exact path="/demos/detailspanel1/*" element={<DetailsPanel1 />}/>
          <Route exact path="/demos/bottommargin1/*" element={<BottomMargin1 />}/>


          {/* --- extra --- */}
          <Route exact path="/react-lumistry-design/*" element={<DashboardPage/>}/>
          <Route exact path="/react-lumistry-uat/*" element={<DashboardPage/>}/>
          <Route exact path="/react-lumistry-demo/*" element={<DashboardPage/>}/>
          {/* <Route exact path="/demo/*" element={<DemoPage/>}/> */}
          <Route path="*" element={<DefaultPage/>}/>

          {/* <Route exact path="/" element={<HomeScreen/>}/>
          <Route exact path="/tasks/*" element={<TasksListScreen/>}/>
          <Route exact path="/messages/*" element={<MessagesInboxScreen/>}/>
          <Route exact path="/schedule/*" element={<ScheduleApptScreen/>}/>
          <Route exact path="/patients/*" element={<PatientsListScreen/>}/>
          <Route exact path="/forms/*" element={<FormsSubsScreen/>}/>
          <Route exact path="/reports/*" element={<DefaultPage/>}/> */}
        </Routes>
      </Wrapper>
    </Router>
  );
}
